var site = site || {};

(function($, prodcat) {
  site.getRouteUrl = function($product, skuBaseId, hash) {
    var skuData = prodcat.data.getSku(skuBaseId);
    var isProdSized = _isSizedProduct(skuData, $product);
    var routePrefix = !isProdSized ? '?shade=' : '?size=';
    var routeString = _getSkuRoute(skuData, $product);

    if (!!routeString) {
      prodcat.ui.routeNoSpaces = _fixedEncodeURIComponent(routeString.toString().split(' ').join('_'));
      if (hash.indexOf('?') >= 0) {
        prodcat.ui.routeNoSpaces += '?' + hash.split('?')[1];
      }
      return routePrefix + prodcat.ui.routeNoSpaces;
    }

    return null;
  }
  // include reserved characters missing from encodeURIComponent()
  function _fixedEncodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }
  function _isSizedProduct(skuData, $product) {
    var prodId = !!skuData ? skuData.PRODUCT_ID : $product.attr('data-product-id');
    var prodData = prodcat.data.getProduct(prodId);
    return (!!prodData && prodData.sized) || $product.attr('data-product-sized') ? 1 : 0;
  }
  function _getSkuRoute(skuData, $product) {
    var routeString = '';
    var isProdSized = _isSizedProduct(skuData, $product);
    if (skuData) {
      if (isProdSized) {
        routeString = _.result(skuData, 'PRODUCT_SIZE');
      } else {
        routeString = _.result(skuData, 'SHADENAME');
      }
    } else {
      routeString = $product.attr('data-product-route') || '';
    }
    return routeString;
  }

  $(document).on('product.updateRoute', '.js-product-ui', function(e, skuBaseId) {
    var $product = $(this);
    var skuData = prodcat.data.getSku(skuBaseId);
    var routeString = _getSkuRoute(skuData, $product);
    newRoute = site.getRouteUrl($product, skuBaseId, window.location.hash);

    if (!!newRoute) {
      history.replaceState({}, routeString, newRoute);
      $product.trigger('product.updateRouteFinished', newRoute);
    }
  });
})(jQuery, window.prodcat = window.prodcat || {});
